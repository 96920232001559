import * as React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, useStaticQuery } from 'gatsby'
import CategoryImage from '../CategoryImage'
import { USD } from '../../helpers/NumberHelper'
import { uniformUrl } from '../../helpers/StringHelpers'
import { ProductTypeHelper } from '../../helpers/ProductTypeHelper'
import { IconSoldLibrary } from '../IconSold'
import Tags, { AllowedTags } from './Tags'
import NewArrivalSlider from '../sliders/NewArrivalSlider'
import { ProductGridTitle } from './ProductTitle'
import useMediaQuery from '../../hooks/useMediaQuery'
import { gridSizes } from '../../common/gatsbyImageData'
import hasBoxAndPapers from '../../helpers/product/hasBoxAndPapers'
import useCDNImage from '../../hooks/useCDNImage'
import YouTube from '../homepage/YouTube'

const NoResultsItemsPerScreen = step => {
  const isLg = useMediaQuery('(min-width: 64em)')
  const isXl = useMediaQuery('(min-width: 80em)')
  const theStep = (step || 0) <= 0 ? Number.MAX_SAFE_INTEGER : step

  const itemsPerScreen = React.useCallback(() => {
    if (isXl) return Math.min(2, theStep)
    if (isLg) return Math.min(1, theStep)
    return Math.min(2, theStep)
  }, [isXl, isLg, theStep])

  return [itemsPerScreen, 'w-1/2 lg:w-full xl:w-1/2']
}

const ProductType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  urlPath: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  small_image: PropTypes.object.isRequired,
  item_type: PropTypes.string,
  item_category: PropTypes.string,
  jewelry_type: PropTypes.string,
  model_no: PropTypes.string,
  case_size_mm: PropTypes.number,
  thumbnail: PropTypes.object.isRequired,
  media_gallery: PropTypes.array.isRequired,
  brand: PropTypes.string,
  model: PropTypes.string,
  price: PropTypes.number,
  special_price: PropTypes.number,
  qty: PropTypes.number,
  productType: PropTypes.string,
})

const PriceCardShort = ({ product }) => {
  const isSale = product.special_price && product.special_price > 0
  const finalPrice = isSale ? product.special_price : product.price
  return (
    <div
      className={
        'mx-auto text-md my-2 flex flex-row text-center font-bold text-sm sm:text-base ' +
        (isSale ? ' text-red-600' : '')
      }
    >
      {USD(finalPrice)}
    </div>
  )
}
PriceCardShort.propTypes = {
  product: ProductType,
}

const ProductGrid = ({
  className,
  products,
  boxAndPapers = false,
  tags = true,
  searchTerm,
  additionalHits = [],
}) => {
  return (
    <div className={className + ' @container'}>
      <div
        className={
          ' grid my-2 grid-flow-row grid-cols-1 xxs:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-2 @[55rem]:gap-x-[1.3vw] sm:gap-y-10 duration-200  '
          // + '@[40rem]:grid-cols-2  @[50rem]:grid-cols-3 @[65rem]:grid-cols-4 '
        }
      >
        <IconSoldLibrary />
        {products.length === 0 && <ProductGridNoResults searchTerm={searchTerm} />}
        {products.map((hit, index) => {
          const url = uniformUrl(hit.urlPath + '/')
          return (
            <ProductGridCard
              index={index}
              key={hit.sku}
              url={url}
              tags={tags}
              hit={hit}
              boxAndPapers={boxAndPapers}
            />
          )
        })}
        {additionalHits.map(hit => (
          // <div className="order-0 xxs:order-2 lg:order-4 2xl:order-6" key={hit.url}>
          <div
            className="rounded-xl md:p-5 order-0 xxs:order-1 lg:order-3 2xl:order-5 col-span-2 bg-gray-50 flex flex-col justify-center "
            key={hit.url}
          >
            <div className="w-full bg-gray-300 ">
              <YouTube
                src={hit.url}
                title={hit.title}
                placeholder={hit.image}
                className="h-full w-full aspect-video"
              />
            </div>
            <h3 className="text-sm md:text-lg font-bold uppercase text-ellipsis my-auto block">
              {hit.title}
            </h3>
          </div>
        ))}
      </div>
    </div>
  )
}

const ProductGridNoResults = ({ searchTerm }) => {
  const { searchTerms } = useStaticQuery(graphql`
    query popularSearchTermsQuery {
      searchTerms: popularSearchTerms
    }
  `)
  const popularSearches = searchTerms
  // const [popularSearches, setPopularSearches] = React.useState(searchTerms)
  // const searchClient = client()
  // React.useEffect(() => {
  //   if(!searchTerm) return
  //   const index = searchClient.initIndex( 'products_query_suggestions')
  //   index.search(searchTerm, {
  //     hitsPerPage: 10,
  //   }).then(({ hits }) => {
  //     if (hits.length === 0) {
  //       hits = searchTerms
  //     } else {
  //       // combine both lists, leave only unique values
  //       hits = [...new Set([...(hits.map(hit => hit.query)), ...searchTerms])]
  //     }
  //     setPopularSearches(hits)
  //   })
  // }, [searchTerm, searchClient])
  return (
    <div className={' col-span-2 lg:col-span-3 2xl:col-span-4 w-full '}>
      <h2 className="text-red-600 font-bold text-xl xl:text-3xl uppercase text-center py-5">
        No results found
        <span className="font-normal text-base text-gray-500 block">
          matching Your search {searchTerm?.length > 0 ? `for "${searchTerm}"` : ''}
        </span>
      </h2>

      <div className="w-full flex flex-col lg:flex-row max-w-[1366px] mx-auto ">
        <div className="max-w-max mx-auto w-full flex flex-col md:flex-row lg:flex-col justify-center items-start gap-5">
          <div className="max-w-max mx-auto md:mx-0 md:w-1/2 lg:w-full ">
            <b>We couldn't find a match for your search!</b>
            <br />
            Try these helpful tips:
            <br />
            <p className="text-gray-500 text-wrap">
              <span className="text-green-600 inline-block">&#10003;</span> Use different or less
              specific words in your search.
            </p>
            <p className="text-gray-500">
              <span className="text-green-600 inline-block">&#10003;</span> Double-check your
              spelling.
            </p>
            <p className="text-gray-500">
              <span className="text-green-600 inline-block">&#10003;</span> Browse our menu options.
            </p>
            <br />
            Need additional help?
            <Link to="/contact" className="text-red-600 mb-5 block">
              Contact us
            </Link>
          </div>
          <div className="max-w-max mx-auto md:mx-0 md:w-1/2 lg:w-full ">
            Popular search terms:
            <div className="flex flex-row flex-wrap gap-3 text-sm py-1">
              {popularSearches.map(hit => (
                <Link
                  to={'/search/#search=' + hit}
                  key={hit}
                  className="max-w-max px-3 py-1 bg-gray-100 hover:bg-gray-200 duration-200 rounded-full"
                >
                  {hit}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <NewArrivalSlider
          itemsPerScreenFactory={NoResultsItemsPerScreen}
          className="w-full lg:w-1/2 mt-5 md:mt-0"
        />
      </div>
    </div>
  )
}
ProductGridNoResults.propTypes = {
  searchTerm: PropTypes.string,
}
export const ProductGridCard = ({ hit, url, /*boxAndPapers = false,*/ tags, index }) => {
  const selectImage = (hit, hasBoxAndPapers) => {
    if (hasBoxAndPapers) {
      const imagesWithBox = hit.media_gallery
        .filter(image => image?.url)
        .filter(
          image =>
            image?.url.toLowerCase().includes(hit.sku.toLowerCase() + 'p') ||
            image?.url.toLowerCase().includes(hit.sku.toLowerCase() + '_p')
        )
      if (imagesWithBox.length > 0) {
        return imagesWithBox[0]
      }
    }
    return hit.small_image || hit.thumbnail
  }
  const image = useCDNImage(selectImage(hit, hasBoxAndPapers), hit, gridSizes)
  return (
    <Link
      to={url}
      className={`group flex flex-col text-center w-full py-2 lg:py-10 bg-gray-50 rounded-xl relative overflow-hidden order-${
        index < 12 ? index : 12
      }`}
    >
      <Tags product={hit} tags={tags} />
      <div
        className={
          'my-1 h-full w-full flex justify-center items-center group-hover:-translate-y-2 duration-500 mix-blend-multiply sm:px-2 lg:px-4'
        }
      >
        <CategoryImage
          sku={hit.sku}
          alt={hit.name}
          title={hit.title}
          normal={image}
          productType={hit.productType || ProductTypeHelper(hit)}
          tags={false}
          product={hit}
          imgStyle={{ objectFit: 'contain' }}
          className={'w-full h-full aspect-square'}
          loading={index < 4 ? 'eager' : 'lazy'}
          sizes="(min-width: 1536px) 25vw, (min-width: 1024px) 33vw, 50vw"
        />
      </div>
      <ProductGridTitle product={hit} />
      <PriceCardShort product={hit} />
      {/* <Link
    to={url}
    className={'mx-auto w-auto bg-red-700 p-4 py-2 text-white hover:bg-red-600'}
  >
    See Details
  </Link> */}
      <p className="hidden text-gray-500 text-sm">{hit.sku}</p>
    </Link>
  )
}
ProductGridCard.propTypes = {
  hit: ProductType.isRequired,
  boxAndPapers: PropTypes.bool,
  url: PropTypes.string.isRequired,
  tags: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.oneOf(AllowedTags))]),
  index: PropTypes.number,
}

ProductGrid.propTypes = {
  products: PropTypes.arrayOf(ProductType).isRequired,
  tags: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.oneOf(AllowedTags))]),
  boxAndPapers: PropTypes.bool,
  searchTerm: PropTypes.string,
  className: PropTypes.string,
  additionalHits: PropTypes.array,
}

PriceCardShort.propTypes = {
  product: ProductType,
}

export const query = graphql`
  fragment ProductCard on STRAPI_PRODUCT {
    name
    productType
    urlPath
    sku
    title
    small_image {
      name
      gatsbyImageDataMock
      url
    }
    thumbnail {
      name
      gatsbyImageDataMock
      url
    }
    media_gallery {
      name
      gatsbyImageDataMock
      url
    }
    brand
    model
    item_type
    item_category
    jewelry_type
    case_size_mm
    model_no
    price
    special_price
    qty
    categories {
      id
      name
      url: urlPath
      strapi_id
    }
  }
`
export default ProductGrid
