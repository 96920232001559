import * as React from 'react'
import { Link, navigate } from 'gatsby'
import { PageGetSurrounding } from '../../helpers/PaginationHelpers'
import PropTypes from 'prop-types'
import { ChevronLeft, ChevronRight } from 'heroicons-react'
import { handleFacetClick } from './FilterPanel'

const PageLink = ({ page, className, baseUrl, onClick, text }) => {
  return baseUrl ? (
    <Link
      className={className}
      to={baseUrl + (page.number > 1 ? '/page-' + page.number : '') + '/'}
      rel={page.rel || undefined}
    >
      {text ? text : page.number}
    </Link>
  ) : (
    <button className={className} onClick={() => onClick && onClick(page.number)}>
      {text ? text : page.number}
    </button>
  )
}

const Pagination = ({ pageInfo, baseUrl, onClick, offset = 0 }) => {
  const pages = PageGetSurrounding(
    pageInfo.currentPage,
    pageInfo.perPage,
    pageInfo.totalCount,
    { xs: 1, sm: 2, md: 3 },
    offset
  )
  const nextPage = pages.find(page => (page.rel || '').match(/next/))
  const prevPage = pages.find(page => (page.rel || '').match(/prev/))

  return (
    <section className={'flex flex-row justify-center my-4'}>
      {prevPage && (
        <PageLink
          page={prevPage}
          className={
            'size-6 text-red-600 hover:bg-red-600 hover:text-white rounded-full mr-2 duration-200'
          }
          baseUrl={baseUrl}
          onClick={onClick}
          text={<ChevronLeft />}
        ></PageLink>
      )}
      Page
      {pages.map(page => {
        const isCurrentPage = page.number === offset + pageInfo.currentPage
        const isOnlyPage = pages.length === 1
        const currentPageStyle = isCurrentPage
          ? 'rounded-full text-white p-1 h-6 block aspect-square flex justify-center items-center ' +
            (isOnlyPage ? 'bg-gray-400 cursor-not-allowed	' : 'bg-red-600')
          : ''
        return (
          <React.Fragment key={page.id}>
            <div className={'px-2 ' + (page.className || '')}>
              {page.text ? (
                page.text
              ) : (
                <PageLink
                  page={page}
                  className={currentPageStyle}
                  baseUrl={baseUrl}
                  onClick={onClick}
                />
              )}
            </div>
          </React.Fragment>
        )
      })}
      {nextPage && (
        <PageLink
          page={nextPage}
          className={
            'size-6 text-red-600 hover:bg-red-600 hover:text-white rounded-full mr-2 duration-200'
          }
          baseUrl={baseUrl}
          onClick={onClick}
          text={<ChevronRight />}
        ></PageLink>
      )}
    </section>
  )
}

PageLink.propTypes = {
  page: PropTypes.shape({
    number: PropTypes.number.isRequired,
  }).isRequired,
  className: PropTypes.string,
  baseUrl: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

Pagination.propTypes = {
  pageInfo: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
  }).isRequired,
  baseUrl: PropTypes.string,
  onClick: PropTypes.func,
  offset: PropTypes.number,
}

export default Pagination
