import * as React from 'react'
import { useState, useEffect, useCallback } from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { IconPage, IconSearch } from '../components/Icons'
import {
  algoliaSearch,
  mergeFacets,
  extractFilter,
  serializeFacets,
  deserializeFacets,
} from '../helpers/AlgoliaClient'
import { handleFacetClick } from '../components/products/FilterPanel'
import ProductGrid from '../components/products/ProductGrid'
import { graphql, Link } from 'gatsby'
import VideoCardGrid from '../components/cms/VideoCardGrid'
import Pagination from '../components/products/Pagination'
import {
  AllowedTags,
  TAG_SALE,
  TAG_NEW,
  TAG_GIA,
  TAG_BOX_AND_PAPERS,
} from '../components/products/Tags'
import SubCategoryMenu from '../components/categories/SubCategoryMenu'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { hideChat, unhideChat } from '../state/popupForms'
import FilterTags from '../components/products/FilterTags'
import SortAndFilterButtonAndOptions from '../components/products/SortAndFilterButtonAndOptions'
import SortByDropDown from '../components/products/SortByDropDown'
import { processGatsbyImageDataMock } from '../common/gatsbyImageData'
import Section from '../components/cms/Section'
import useMediaQuery from '../hooks/useMediaQuery'
import TailwindScreens from '../helpers/tailwind'
import { CombineSections } from '../components/cms/SectionLayout'

const CategoryPage = ({ location, data, pageContext }) => {
  const {
    category,
    subcategories,
    facets: initialFacets,
    items: { pageInfo, nodes: _hits },
  } = data
  const boxAndPapersCategory = category.name.toLowerCase().includes('with box and papers')
  const [filterPanelOn, setFilterPanelOn] = useState(false)
  const [sortPanelOn, setSortPanelOn] = useState(false)
  const [hits, setHits] = useState(_hits)
  const pageSections = CombineSections(category.sections || [])

  const [searchPages, setSearchPages] = useState({
    currentPage: 0,
    perPage: 0,
    totalCount: 0,
    itemCount: 0,
    searched: false,
  })
  const [facets, setFacets] = useState(initialFacets)
  const videos = category.videos?.videos || []
  videos.forEach(video => {
    processGatsbyImageDataMock(video.thumbnail)
  })
  const { showVideos } = pageContext
  const additionalHits =
    showVideos && pageContext.page === 0 && !searchPages.searched ? [videos[videos.length - 1]] : []

  const [facetFilters, setFacetFilters] = useState(deserializeFacets(location.hash))
  const [keyword, setKeyword] = useState('')
  const dispatch = useDispatch()

  const isMd = useMediaQuery(TailwindScreens.md)
  React.useEffect(() => {
    if (isMd) return
    if (filterPanelOn) dispatch(hideChat())
    else dispatch(unhideChat())
  }, [filterPanelOn, isMd])

  const navigateToPage = useCallback(
    _page => {
      if (Number.isNaN(_page) || _page + 1 === pageInfo.currentPage) {
        setFacets(initialFacets)
        setFacetFilters({})
        setSearchPages(s => ({ ...s, searched: false }))
        setHits(_hits)
        return
      }
      if (_page < 0) _page = 0
      if (_page > pageInfo.pageCount) _page = pageInfo.pageCount - 1
      window.location = category.url + `/page-${_page + 1}/`
    },
    [pageInfo, category, _hits]
  )

  useEffect(() => {
    if (!window) return
    const _facets = deserializeFacets(location.hash)
    const _keyword = (extractFilter(_facets, 'search', false) || '').toString().trim()
    const _sortBy = extractFilter(_facets, 'sort_by', false)
    const _minPrice = Number.parseFloat(extractFilter(_facets, 'min_price', false)) || -1
    const _maxPrice = Number.parseFloat(extractFilter(_facets, 'max_price', false)) || -1
    let _page = parseInt(extractFilter(_facets, 'page'))
    if (_page > 0) _page = _page - 1
    if (Object.keys(_facets || {}).length === 0 && _keyword === '') {
      navigateToPage(_page)
      return
    }
    setKeyword(_keyword)
    setFacetFilters(_facets)
    const controller = new AbortController()
    algoliaSearch(
      _keyword,
      _page,
      _facets,
      _sortBy,
      controller,
      category,
      true,
      boxAndPapersCategory,
      [_minPrice, _maxPrice]
    ).then(searchResponse => {
      if (controller.signal.aborted) return
      // window.scrollTo(0, 0)
      setFacets(prevFacets => mergeFacets(prevFacets, _facets, searchResponse))
      setHits(searchResponse.hits)
      setSearchPages({
        currentPage: searchResponse.page,
        perPage: searchResponse.hitsPerPage,
        totalCount: searchResponse.nbHits,
        itemCount: searchResponse.hits.length,
        searched: true,
      })
    })
    return () => {
      controller.abort()
    }
  }, [location.hash, navigateToPage, category, setKeyword, setFacetFilters, setFacets, mergeFacets])

  const onSearch = useCallback(
    e => {
      e.preventDefault()
      if (!window) return
      let hash = deserializeFacets(window.location.hash) || {}
      hash['search'] = ['search:' + keyword]
      window.location.hash = serializeFacets(hash)
    },
    [keyword]
  )

  const handleFacet = useCallback(
    (key, facet) => {
      if (!window) return
      window.location.hash = serializeFacets(
        handleFacetClick(key, facet, deserializeFacets(window.location.hash))
      )
      if (sortPanelOn && key === 'sort_by') {
        setFilterPanelOn(false)
      }
      if (window.location.hash === '') {
        history.pushState('', document.title, window.location.pathname + window.location.search)
      }
    },
    [handleFacetClick, serializeFacets, deserializeFacets, sortPanelOn]
  )

  const clearFacetFilters = useCallback(() => {
    if (!window) return
    window.location.hash = '#'
    setKeyword('')
  }, [location, setKeyword])

  const changePage = useCallback(
    page => {
      if (!window) return
      window.location.hash = serializeFacets({
        ...deserializeFacets(window.location.hash),
        page: ['page:' + page],
      })
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    [serializeFacets, deserializeFacets]
  )
  let tags = true

  switch (category.name.toUpperCase()) {
    case 'NEW ARRIVALS':
      tags = AllowedTags.filter(t => t !== TAG_NEW)
      break
    case 'SALE':
      tags = AllowedTags.filter(t => t !== TAG_SALE)
      break
    default:
      if (category.name.toUpperCase().indexOf('GIA ') === 0) {
        tags = AllowedTags.filter(t => t !== TAG_GIA)
      } else if (category.name.toUpperCase().indexOf('WITH BOX AND PAPERS') >= 0) {
        tags = AllowedTags.filter(t => t !== TAG_BOX_AND_PAPERS)
      }
  }

  const subcats =
    subcategories?.nodes
      ?.map(subcategory => ({
        name: subcategory.name.replace(category.name.replace(' Watches', ''), ''),
        url: subcategory.url,
      }))
      .filter(subcat => subcat.name.toLowerCase().indexOf('view all') === -1) || []
  // sort, put 'View All' at the end
  subcats.sort((a, b) => {
    if (a.name.toLowerCase().indexOf('view all') >= 0) return 1
    if (b.name.toLowerCase().indexOf('view all') >= 0) return -1
    return a.name.localeCompare(b.name)
  })

  //const showSubCat = subCatToggle.hover || subCatToggle.clicked

  let paginationPageInfo

  // update pagination object for those pages where we have video in listing

  if (pageContext.perPagePagination) {
    paginationPageInfo = {
      ...data.items.pageInfo,
      perPage: pageContext.perPagePagination,
      totalCount: pageContext.totalCountPagination,
    }
  } else {
    paginationPageInfo = data.items.pageInfo
  }

  return (
    <Layout
      className={'mx-auto flex flex-col'}
      breadcrumbs={category.breadcrumbs}
      submenu={
        subcats.length > 0 && (
          <div className="w-full hidden xl:flex bg-gray-100 border-b border-gray-300 py-1 relative">
            <div className="w-10/12 mx-auto h-8 overflow-y-hidden pr-8 text-sm uppercase max-w-[1366px] flex gap-4 flex-wrap py-1 text-gray-600 relative">
              {subcats.length > 0 &&
                subcats.map(subcategory => (
                  <Link
                    to={subcategory.url}
                    key={subcategory.url}
                    className="border-gray-300  whitespace-nowrap text-gray-600 hover:text-black duration-150 ease-in"
                  >
                    {subcategory.name}
                  </Link>
                ))}
            </div>
            <div className="absolute 2xl:right-[calc(50vw-683px)] xl:right-[8.33vw]">
              {subcats.length > 0 && (
                <SubCategoryMenu alignment="right-0" subcategories={subcats} />
              )}
            </div>
          </div>
        )
      }
    >
      <Seo
        seo={category.seo}
        title={`Used ${category.name} - Certified Pre-Owned ${
          pageInfo.currentPage > 1 ? ` | Page ${pageInfo.currentPage}` : ''
        }`}
        paginationTitle={pageInfo.currentPage > 1 ? `Page ${pageInfo.currentPage}` : ''}
        canonical={
          category.url + (pageInfo.currentPage > 1 ? `/page-${pageInfo.currentPage}/` : '')
        }
      />

      <header
        className={
          '  mx-auto flex w-full grow items-center justify-between gap-5 whitespace-pre-wrap px-5 md:w-10/12 lg:px-0 duration-150'
        }
      >
        <div className="text-xl font-black uppercase lg:text-2xl xl:text-3xl w-full flex flex-row items-center mb-2 ">
          <h1 className="md:h-7 flex flex-row gap-2 ">
            {category.headerH1 || category.name}
            {pageInfo.currentPage > 1 ? (
              <div className="relative size-6 lg:size-8 order-first">
                <IconPage page={pageInfo.currentPage} className="w-full h-full relative" />
              </div>
            ) : null}
          </h1>
          {subcats.length > 0 && <SubCategoryMenu subcategories={subcats} className="xl:hidden" />}
          {/* {pageInfo.currentPage > 1 && ` - Page ${pageInfo.currentPage}`} */}
          {/*Page {pageInfo.currentPage} of {pageInfo.pageCount} {" "}*/}
          {/*Total {pageInfo.totalCount}*/}
          {/*Item {pageInfo.itemCount}*/}
        </div>
        <form
          className="hidden min-w-max max-w-[700px] grow justify-self-end md:block lg:pr-5"
          action={'/search'}
          method={'get'}
          onSubmit={onSearch}
        >
          <div
            className={
              'mx-auto my-3 hidden w-full flex-row rounded-full border border-gray-300 duration-300 hover:shadow-lg focus:shadow-lg md:flex'
            }
          >
            <input
              type={'text'}
              aria-label={'Search'}
              name={'q'}
              placeholder={'Search' + (category.name ? ' ' + category.name : '')}
              value={keyword}
              id={'search-keywords'}
              onChange={e => {
                setKeyword(e.target.value)
              }}
              className={' w-full rounded-l-full py-3 px-5 placeholder-gray-500 outline-0 lg:mr-5'}
            />
            <button className={'my-auto h-10 border-l border-gray-300 px-5 text-gray-500 '}>
              <IconSearch />
              <span className={'sr-only'}>Search</span>
            </button>
          </div>
        </form>
      </header>

      {pageInfo.currentPage === 1 && category.descriptionTop && (
        <section className="desc cms mb-5 mx-auto md:px-5 w-10/12 lg:px-0 order-1 md:order-none ">
          <div dangerouslySetInnerHTML={{ __html: category.descriptionTop }} />
        </section>
      )}
      <div className=" hidden mx-auto w-full md:w-10/12 mt-3  md:grid md:grid-cols-[1fr_auto] gap-4 items-center justify-between px-5 lg:px-0 ">
        <div className="w-full text-sm flex items-center flex-row overflow-x-auto h-12 ">
          <div className="min-w-[8rem] max-w-max md:w-1/3 lg:w-1/4 xl:w-1/5 ">
            <button
              className={
                'flex w-full items-center text-center text-sm text-gray-500 hover:text-red-700 '
              }
              onClick={() => {
                setFilterPanelOn(!filterPanelOn)
              }}
            >
              <div
                className={
                  (filterPanelOn ? 'border-red-700 bg-red-700 ' : 'border-gray-400 bg-gray-400 ') +
                  ' relative mr-3 flex h-6 w-10 items-center rounded-full border-2 duration-150 '
                }
              >
                <div
                  className={
                    'absolute h-5 w-5 rounded-full bg-white duration-500 ease-[cubic-bezier(.59,-0.24,.43,1.37)] ' +
                    (filterPanelOn ? 'left-0' : 'left-4')
                  }
                />
              </div>
              <nobr>
                FILTER{' '}
                {filterPanelOn ? (
                  <>
                    ON
                    <span className="w-[0.3271875em]" />
                  </>
                ) : (
                  'OFF'
                )}
              </nobr>
            </button>
          </div>
          {/* These are the filter Tags */}
          {facetFilters && (
            <div className="w-full flex justify-start gap-2 text-sm relative overflow-y-hidden mx-auto lg:order-1 xl:order-2 md:col-span-2 xl:col-span-1">
              <div className="absolute right-0 bg-gradient-to-l from-white to-transparent w-10 h-8 z-10" />
              <div className="absolute left-0 bg-gradient-to-r from-white to-transparent w-10 h-8 z-10" />

              <div className="touch-pan-x flex flex-row gap-2 overflow-y-hidden overflow-x-auto px-10 z-0 pb-3 md:pb-0 scroll-smooth scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent scrollbar-rounded-lg">
                <FilterTags
                  facetFilters={facetFilters}
                  clearFacetFilters={clearFacetFilters}
                  handleFacet={handleFacet}
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-row justify-end items-center gap-4 order-5">
          {/* This is the total results */}
          <div className="min-w-max flex justify-end h-5">
            <b>{searchPages?.searched ? searchPages.totalCount : pageInfo.totalCount}</b>&nbsp;
            Results
          </div>
          <p className="h-6 p-[0.07rem] bg-gray-400"></p>
          {/* This is sort by options on desktop */}
          <div className="min-w-max flex justify-end items-center ">
            Sort: &nbsp;
            <SortByDropDown facetFilters={facetFilters} handleFacet={handleFacet} />
          </div>
        </div>
      </div>

      <section className="relative mx-auto flex w-full flex-col justify-start bg-white md:w-10/12 md:flex-row">
        <SortAndFilterButtonAndOptions
          totalCount={searchPages?.searched ? searchPages.totalCount : pageInfo.totalCount}
          hasResults={hits.length > 0}
          clearFacetFilters={clearFacetFilters}
          sortPanelOn={sortPanelOn}
          filterPanelOn={filterPanelOn}
          setFilterPanelOn={setFilterPanelOn}
          facets={facets}
          facetFilters={facetFilters}
          handleFacet={handleFacet}
          setSortPanelOn={setSortPanelOn}
        />

        <div
          className={
            (filterPanelOn ? ' md:w-2/3 lg:w-3/4 xl:w-4/5 lg:ml-5 ' : 'w-full ') +
            ' right-0 px-5 lg:px-0 duration-300 sticky h-full top-5 '
          }
        >
          <ProductGrid
            products={hits}
            boxAndPapers={boxAndPapersCategory}
            tags={tags}
            additionalHits={additionalHits}
          />
        </div>
      </section>

      {(searchPages.searched || (hits && hits.length > 0)) &&
        (searchPages.searched ? (
          <Pagination pageInfo={searchPages} offset={1} onClick={changePage} />
        ) : (
          <Pagination pageInfo={paginationPageInfo} baseUrl={category.url} />
        ))}
      {filterPanelOn === false && pageInfo.currentPage === 1 && (
        <>
          <div className="mx-auto w-10/12 cms order-2 flex flex-col gap-10">
            <div className="" dangerouslySetInnerHTML={{ __html: category.description }} />
          </div>
          {pageSections &&
            pageSections.map(section => {
              return (
                <Section
                  key={section.id}
                  section={section}
                  className={'w-10/12 max-w-[1366px] mx-auto relative'}
                />
              )
            })}
          <div className="mx-auto w-10/12 cms order-2 flex flex-col gap-10">
            <VideoCardGrid videos={videos} />
            <div className="" dangerouslySetInnerHTML={{ __html: category.descriptionBottom }} />
          </div>
        </>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($categoryId: Int!, $perPage: Int!, $skip: Int!) {
    facets: allStrapiProduct(
      sort: { fields: stock_date, order: DESC }
      filter: { categories: { elemMatch: { strapi_id: { eq: $categoryId } } }, qty: { gt: 0 } }
    ) {
      ...FacetFilters
    }
    items: allStrapiProduct(
      sort: { fields: [in_stock, stock_date], order: [DESC, DESC] }
      limit: $perPage
      skip: $skip
      filter: { categories: { elemMatch: { strapi_id: { eq: $categoryId } } } }
    ) {
      nodes {
        ...ProductCard
      }
      pageInfo {
        perPage
        pageCount
        totalCount
        itemCount
        currentPage
        hasNextPage
        hasPreviousPage
      }
    }
    subcategories: allStrapiCategory(filter: { strapi_parent: { id: { eq: $categoryId } } }) {
      nodes {
        name
        url: urlPath
      }
    }
    category: strapiCategory(strapi_id: { eq: $categoryId }) {
      strapi_id
      name
      description
      url: urlPath
      headerH1
      description
      descriptionTop
      descriptionBottom

      videos {
        heading
        videos {
          url
          title
          thumbnail {
            gatsbyImageDataMock
          }
        }
      }
      breadcrumbs {
        url: urlPath
        name
      }
      seo {
        ...SEO
      }
      sections {
        ... on STRAPI__COMPONENT_SECTION_PARAGRAPH {
          ...ParagraphSection
        }
        ... on STRAPI__COMPONENT_SECTION_ACCORDION_SECTION {
          ...AccordionSection
        }
        # ... on STRAPI__COMPONENT_SHARED_LINK {
        #   ...CtaLink
        # }
        # ... on STRAPI__COMPONENT_SECTION_TESTIMONIALS {
        #   ...TestimonialsSection
        # }
        # ... on STRAPI__COMPONENT_SECTION_CONTACT_US_MAP {
        #   strapi_component
        # }
        ... on STRAPI__COMPONENT_SECTION_FORM {
          ...FormSection
        }
        ... on STRAPI__COMPONENT_SECTION_IMAGE_SECTION {
          ...ImageSection
        }
        ... on STRAPI__COMPONENT_SECTION_NUMBERED_LIST {
          ...NumberedListSection
        }
        ... on STRAPI__COMPONENT_SECTION_SECTION_LAYOUT {
          ...SectionLayout
        }
        # ... on STRAPI__COMPONENT_SECTION_COMMON_QUESTIONS {
        #   ...HaveQuestionsSection
        # }
        # ... on STRAPI__COMPONENT_SECTION_PRODUCT_GRID {
        #   ...ProductGridSection
        # }
      }
    }
  }
`
CategoryPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default CategoryPage
