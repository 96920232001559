import * as React from 'react'
import PropTypes from 'prop-types'
import { IconPlus } from '../Icons'
import { getValue } from '../../helpers/StringHelpers'

const FilterTags = ({ facetFilters, clearFacetFilters, handleFacet, sortBy = false }) => {
  return (
    <>
      {facetFilters &&
      Object.keys(facetFilters).filter(key => (sortBy ? true : key !== 'sort_by')).length > 0 ? (
        <button
          onClick={clearFacetFilters}
          className="max-w-max px-3 py-1 bg-gray-100 hover:bg-gray-200 hover:text-gray-400 duration-200 rounded-full h-8 cursor-not-allowed"
        >
          <nobr>Clear All</nobr>
        </button>
      ) : (
        <p className="border border-transparent py-1 ">&nbsp;</p>
      )}

      {Object.getOwnPropertyNames(facetFilters).map(filterCategory => {
        return (
          <React.Fragment key={filterCategory}>
            {facetFilters[filterCategory]
              .map(filter => {
                const facetParts = filter.split(':')
                const facet = {
                  key: facetParts[0],
                  name: facetParts[1],
                }
                return { facet, filter }
              })
              .filter(({ facet }) => (sortBy ? true : facet.key !== 'sort_by'))
              .map(({ facet, filter }) => {
                return (
                  <button
                    key={filter}
                    className="max-w-max px-3 py-1 bg-gray-100 hover:bg-gray-200 hover:text-gray-400 duration-200 rounded-full h-8 cursor-not-allowed"
                    onClick={e => {
                      if (handleFacet) {
                        handleFacet(filterCategory, facet, e)
                      }
                    }}
                  >
                    <nobr>{getValue(facet.key, facet.name)}</nobr>
                  </button>
                )
              })}
          </React.Fragment>
        )
      })}
    </>
  )
}
FilterTags.propTypes = {
  facetFilters: PropTypes.object.isRequired,
  clearFacetFilters: PropTypes.func.isRequired,
  handleFacet: PropTypes.func.isRequired,
  sortBy: PropTypes.bool,
}

export default FilterTags
