const PageGetSurrounding = (currentPage, perPage, totalItems, surrounding = { xs: 1, sm: 2, md: 3 }, offset=0) => {
  const pages = []
  currentPage = currentPage + offset
  const totalPages = Math.ceil(totalItems / perPage)
  if(totalPages <= 6) {
    for(let i = 1; i <= totalPages; ++i) {
      pages.push({ id: `page-${i}`, number: i })
    }
    return pages
  }
  let mapSurrounding = () => {
    if (!surrounding)
      return {
        xs: { surrounding: 1 },
        sm: { surrounding: 2 },
        md: { surrounding: 3 },
      }
    if (typeof surrounding === 'number') {
      return { xs: { surrounding } }
    }
    return Object.keys(surrounding).reduce((acc, key) => {
      acc[key] = { surrounding: surrounding[key] }
      return acc
    }, {})
  }
  const screenSurroundingConfig = mapSurrounding()
  let minLeft = totalItems
  let maxRight = 1
  Object.keys(screenSurroundingConfig).forEach(screen => {
    const config = screenSurroundingConfig[screen]
    config.left = currentPage - config.surrounding > 3 ? currentPage - config.surrounding : 1
    config.right = currentPage + config.surrounding < totalPages - 2 ? currentPage + config.surrounding : totalPages
    if (config.left < minLeft) minLeft = config.left
    if (config.right > maxRight) maxRight = config.right
  })
  const hideIfHasPage = num => {
    const hideClassNames = Object.keys(screenSurroundingConfig).map(screen => {
      const config = screenSurroundingConfig[screen]
      if (config.left <= num && num <= config.right) return `${screen}:hidden`.replace('xs:', '')
    })
    return hideClassNames.join(' ')
  }
  const showIfHasPage = num => {
    const hideClassNames = Object.keys(screenSurroundingConfig).map(screen => {
      const config = screenSurroundingConfig[screen]
      if (config.left > num || num > config.right) return `${screen}:hidden`.replace('xs:', '')
      return `${screen}:block`.replace('xs:', '')
    })
    return hideClassNames.join(' ')
  }
  if (minLeft === 1) minLeft += 1
  if (maxRight === totalPages) maxRight -= 1
  pages.push({ id: 'first', number: 1, rel: currentPage === 2 ? 'start prev' : 'start' })
  pages.push({ id: 'first-1', text: '...', className: hideIfHasPage(2) })
  for (let i = minLeft; i <= maxRight; ++i) {
    pages.push({
      id: `page-${i}`,
      className: showIfHasPage(i),
      number: i,
      rel: i === currentPage - 1 ? 'prev' : i === currentPage + 1 ? 'next' : undefined,
    })
  }
  pages.push({ id: 'last-1', text: '...', className: hideIfHasPage(totalPages - 1) })
  pages.push({ id: 'last', number: totalPages, rel: currentPage == totalPages - 1 ? 'next end' : 'end' })
  return pages
}

export { PageGetSurrounding }
